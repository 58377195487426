import screen1 from "../images/newProductPage/czech/1.png";
import screen2 from "../images/newProductPage/czech/2.png";
import screen3 from "../images/newProductPage/czech/3.png";
import screen4 from "../images/newProductPage/czech/4.png";
import screen5 from "../images/newProductPage/czech/5.png";
import screen6 from "../images/newProductPage/czech/6.png";
import screen7 from "../images/newProductPage/czech/7.png";

import Ildiko from "../images/newProductPage/hungarian/people/Ildiko.png";
import Katalin from "../images/newProductPage/hungarian/people/Katalin.png";
import Istvan from "../images/newProductPage/hungarian/people/Istvan.png";
import Zoltan from "../images/newProductPage/hungarian/people/Zoltan.png";
import Gabor from "../images/newProductPage/hungarian/people/Gabor.png";
import Maria from "../images/newProductPage/hungarian/people/Maria.png";

import new_england from "../images/newProductPage/languages/UK.png";
import new_french from "../images/newProductPage/languages/FR.png";
import new_spanish from "../images/newProductPage/languages/ES.png";
import new_niderland from "../images/newProductPage/languages/ND.png";
import new_germany from "../images/newProductPage/languages/DE.png";
import new_italy from "../images/newProductPage/languages/IT.png";
import new_russia from "../images/newProductPage/languages/RU.png";
import new_portugal from "../images/newProductPage/languages/PR.png";
import new_polish from "../images/newProductPage/languages/PL.png";

import learning1 from "../images/learning/nauka_ikona1.png";
import learning2 from "../images/learning/nauka_ikona2.png";
import learning3 from "../images/learning/nauka_ikona3.png";

import mockup from "../images/mockup_cz.png";

import dlaczego1 from "../images/newProductPage/why/dlaczego_1.webp";
import dlaczego2 from "../images/newProductPage/why/dlaczego_2.webp";
import dlaczego3 from "../images/newProductPage/why/dlaczego_3.webp";

import zdjecie1 from "../images/newProductPage/mission/women_square.png";
import zdjecie2 from "../images/newProductPage/mission/latop_square.png";
import zdjecie3 from "../images/newProductPage/mission/people_square.png";

export const czech = {
  navbar: {
    data: [
      { title: "Objednat kurz", path: "/course" },
      { title: "Přihlásit se", path: "" },
      { title: "O platformě lexicos", path: "/product" },
      { title: "O nás", path: "/about" },
    ],
  },
  mainPage: {
    product_page: {
      hero: {
        languages: [
          "anglicky",
          "španělsky",
          "nizozemsky",
          "německy",
          "italsky",
          "rusky",
          "portugalsky",
          "polsky",
        ],
        title: "Mluvte",
        prefix: "",
        sufix: "s jistotou!",
        subtitle: `Získejte rychle jistotu díky lekcím zaměřeným na konverzaci v
        reálném životě.`,
        buttonText: "Order Now!",
      },
      labelInfo: {
        number: "9",
        numberDescription: "jazyků na výběr",
        itemTwo: "14 000 hodnocení",
        itemThree: {
          top: "Více než 200k",
          bottom: "studentů",
        },
      },
      learning: {
        title: "Jak kurz vypadá?",
        config: [
          {
            category: "Lekce z reálného života",
            description:
              "Každá úroveň výuky se skládá z desítek lekcí. Pokud nevíte, na jaké jste úrovni - začněte se základy. Naučte se pozdravy, dny v týdnu, jak si objednat jídlo v restauraci. Každá lekce obsahuje příklady z každodenního života. ",
          },
          {
            category: "Cvičení",
            description:
              "Ať už vás luštění křížovek baví denně nebo se jim naopak raději vyhýbáte, nelze popřít, že tyto mentální hádanky výborně trénují paměť a umožňují opakování naučené látky.",
          },
          {
            category: "Slova, fráze a výrazy",
            description:
              "Každá lekce je doprovázena pečlivě vybranými obrázky, díky nimž je proces učení intuitivní a kontextuální. Nejčastěji používaná témata pokrývají širokou škálu slovní zásoby z každodenního života a cestování, což umožňuje komunikovat v těchto oblastech bez tzv. jazykového bloku.",
          },
          {
            category: "Zeptejte se odborníka",
            description:
              "Máte problém týkající se jazyka nebo potřebujete pomoc s cvičením? Jsme tady, abychom vám pomohli! Naše funkce Zeptejte se odborníka vám dává možnost obrátit se na odborníka na jazyky, kdykoli potřebujete pomoc.",
          },
        ],
        photoData: [
          screen1,
          screen2,
          screen3,
          screen4,
          screen5,
          screen6,
          screen7,
        ],
      },
      languages: {
        languages: [
          { image: new_england, lang: "Angličtina" },
          { image: new_french, lang: "Francouzština" },
          { image: new_spanish, lang: "Španělština" },
          { image: new_niderland, lang: "Holandštinautch" },
          { image: new_germany, lang: "Němčina" },
          { image: new_italy, lang: "Italština" },
          { image: new_russia, lang: "Ruština" },
          { image: new_portugal, lang: "Portugalština" },
          { image: new_polish, lang: "Polsky" },
        ],
        title: "Dostupné jazyky:",
        description: `Připravte se na příští cestu, doplňte si jazykové znalosti do
        životopisu nebo se ponořte do nové kultury: prozkoumejte rozsáhlou
        sbírku lekcí lexicos a naučte se, co potřebujete k tomu, abyste mohli
        začít mluvit, bez ohledu na úroveň vašich zkušeností.`,
      },
      success: {
        title: "Úspešné príbehy",
        subtitle: `Podívejte se, jak se ostatní zdokonalují v lexicos`,
        config: [
          {
            title: "Praktické lekce",
            name: "Kateřina",
            comment: `Ať už jste začátečník, který se teprve začíná učit základy, nebo pokročilý student, který si chce rozšířit slovní zásobu, lexicos nabízí něco pro každého. Jednou z věcí, které se mi na této platformě líbí, je rozmanitost lekcí, od objednávání jídla v restauraci až po objednávání taxíku, díky čemuž je učení realistické a praktické. Kromě toho je velkým plusem začlenění frází z reálného života a nahrávek pro správnou výslovnost.`,
            photo: Ildiko,
          },
          {
            title: "Praktické lekce",
            name: "Mirka",
            comment: `Líbí se mi, že lekce lexicos jsou přizpůsobeny reálným situacím, takže je pro mě snadné použít to, co jsem se naučila, v reálném světě. Po třech měsících studia mi byla nabídnuta nová práce! `,
            photo: Katalin,
          },
          {
            title: "Zajímavá platforma",
            name: "Jakub",
            comment: `lexicos je vynikající platformou pro výuku jazyků. Díky rozmanitosti lekcí a cvičení je výuka zajímavá a poutavá. Díky učení jazyka jsem se přes internet seznámil s dívkou z Německa, se kterou se brzy ožením. Doporučuji 😊
            `,
            photo: Istvan,
          },
          {
            title: "Používám v práci!",
            name: "Eva",
            comment: `Produkt lexicos používám již několik měsíců a mohu s jistotou říci, že mi změnil život. V práci jsem byl povýšen! Vždycky jsem měl zájem naučit se nový jazyk, ale nikdy jsem na to neměl čas ani prostředky. S lexicos se mohu učit svým vlastním tempem a podle svého vlastního rozvrhu.`,
            photo: Zoltan,
          },
          {
            title: "Příjemné učení",
            name: "Jiří",
            comment: `Podle mého názoru je lexicos úžasnou platformou pro každého, kdo si chce zlepšit své jazykové znalosti. Platforma je navržena tak, aby se učení nového jazyka stalo příjemným zážitkem.
            `,
            photo: Gabor,
          },
          {
            title: "Používám několik měsíců",
            name: "Martina",
            comment: `Používám lexicos již několik měsíců a musím říct, že pro mě změnil pravidla hry. Jako osoba, která se vždycky zajímala o učení nového jazyka, ale nikdy k tomu neměla příležitost, jsem se díky lexicos mohla učit snadno a pohodlně. Nyní rozumím lidem z různých kultur a dokážu s nimi komunikovat, což mi rozšířilo rozhled a otevřelo nové možnosti..`,
            photo: Maria,
          },
        ],
      },
    },
    course: {
      title: "Jaký je kurz?",
      mockuplexicos: mockup,
      right:
        "Na platformě lexicos nenajdete nudná, suchá gramatická pravidla, která si musíte zapamatovat za jeden den. Naučíte se věty a výrazy tak, jak se používají v každodenním životě, a poté si je upevníte v paměti pomocí jednoduchých cvičení a opakování.Kurz byl navržen tak, aby učení bylo příjemné. Během kurzu máte možnost využít:",
      right2Title: "Lekce ze skutečného života",
      right2Bottom:
        "Každá úroveň výuky se skládá z několika desítek lekcí. Pokud nevíte, na jaké jste úrovni, začněte se základy. Naučte se pozdravy, dny v týdnu, pocity. Naučte se, jak si objednat jídlo v restauraci a jak si objednat taxi. Každá lekce obsahuje příklady z každodenního života. Každá lekce je trochu jiná, skládá se z různých úkolů a cvičení, ale všechny do sebe zapadají - takže si jazyk snadno osvojíte. Lekci můžete zahájit kdykoli.",
      flexDown: [
        {
          title: "Při učení se setkáte s prvky, jako jsou:",
          text: `Nahrávky s výukou správné výslovnosti,`,
          text1: "Fráze a věty ze života,",
          text2: "Obrázky, které vám pomohou zapamatovat si slova a fráze ",
          text3: "prostřednictvím vizuálních asociací,",
          text4: "...a mnoho dalších.",
        },
        {
          title: "Cvičení",
          text: `Ať už křížovky řešíte rádi každý den, nebo se jim naopak raději vyhýbáte, nelze popřít, že tyto mentální hádanky výborně trénují paměť a umožňují opakování naučené látky.`,
        },
        {
          title: "Opakování",
          text: `Pokročilejší účastníci si mohou opakovat slovní zásobu a ověřovat své znalosti ve všech tematických oblastech v režimu průběžného učení. Obsahuje veškerou dostupnou slovní zásobu ze všech kategorií. Učící se algoritmus neustále kontroluje úroveň znalostí a přizpůsobuje další postup.`,
        },
      ],
    },
    learningWithlexicos: {
      title: "Učení s lexicos je:",
      cards: [
        {
          icon: learning1,
          headline: `Špičková kvalita`,
          text: ` Při vývoji lexicos se tým odborníků zaměřil na jeden nejdůležitější cíl: aby naše metoda umožnila zvládnout cizí jazyk co nejrychleji (bez ohledu na věk studenta!).
          `,
        },
        {
          icon: learning2,
          headline: `Lekce bez opuštění domova`,
          text: ` K používání aplikace lexicos pro učení nepotřebujete nic jiného než telefon, počítač nebo tablet. S lexicos můžete studovat kdekoli: ve frontě, v autobuse, a především můžete studovat doma a nemusíte nikam chodit. Učíte se ve vaně nebo při venčení psa? S programem Euréka je to možné!
          `,
          text2:
            " Učení s lexicos vám tak ušetří spoustu času stráveného dojížděním a učení můžete snadno zařadit do svého rozvrhu! ",
        },
        {
          icon: learning3,
          headline: `Krátké, ale systematické lekce`,
          text: ` Při učení jazyka je nejdůležitější pravidelnost. Zároveň je pro všechny z nás obtížné najít si na to potřebný čas. S tímto vědomím byla Euréka navržena tak, aby výuka probíhala v krátkých, ale systematických lekcích.`,
        },
      ],
    },
    whylexicos: {
      title: "Proč  lexicos?",
      textUp:
        "Je to platforma s mnohostranným přístupem k učení, která vám pomůže zvládnout nový jazyk tak, jak se to stalo s vaším rodným jazykem - přirozeně.",
      textBottom:
        "Učení se cizímu jazyku může změnit život. Nejenže vám otevře okno do světa plného profesních i osobních příležitostí, ale také rozvíjí mysl a posiluje sebevědomí. S tím, jak se díky technologiím, jako je internet, zmenšuje vzdálenost a my se stáváme členy globální komunity, se schopnost učit se jazyk stává nezbytnou součástí naší interakce se světem a úspěchu.",
      cards: [
        {
          headline: `Naučte se fráze a výrazy ze skutečného života pomocí naší pokročilé technologie učení.`,
          text: `Díky tisícům grafických a zvukových materiálů uvidíte a uslyšíte jako rodilí mluvčí.
          `,
        },
        {
          headline: `Získejte jistotu v mluvení. Skutečným cílem učení jazyka je také být srozumitelný.`,
          text: `S pomocí lexicos stačí jen zopakovat slovní zásobu, kterou jste slyšeli - nebojte se mluvit! Náš systém vás opraví.
          `,
        },
        {
          headline: `Nedovolte, aby vám slova unikla z paměti. Učte se pomocí různých cvičení. `,
          text: `Prvky aplikace jsme pečlivě navrhli tak, aby se vaše mysl nikdy nenudila. Neustále připravujeme a přidáváme do lexicos nové výzvy, aby bylo učení zábavné.
          `,
        },
        {
          headline: `Ponořte se do cizího jazyka. Snažte se plně porozumět novému jazyku na základě znalosti kontextu.`,
          text: `Díky tisícům grafických a zvukových materiálů uvidíte a uslyšíte jako rodilí mluvčí.
          `,
        },
      ],
    },
  },
  aboutUs: {
    hero: {
      title: "Poslání",
      description: `Naším nejvyšším cílem v Verbalii je umožnit každému přístup k osobnímu učiteli díky technologii. Věříme, že jazyk je mostem mezi kulturami a naším posláním je překonávat komunikační bariéry a sbližovat lidi prostřednictvím jazykového vzdělávání.`,
    },
    why: {
      title: "Proč my?",
      config: [
        {
          title: "Inovativní výukové metody: ",
          image: dlaczego1,
          description:
            "Náš algoritmus strojového učení přizpůsobuje materiály úrovni každého uživatele, což zaručuje efektivní a personalizované učení.",
        },
        {
          title: "Široká nabídka jazyků: ",
          image: dlaczego2,
          description:
            "Nabízíme kurzy nejvíce používaných světových jazyků. To Vám umožňuje vybrat si jazyk podle Vaší potřeby.",
        },
        {
          title: "Vzdělávání skrze zábavu: ",
          image: dlaczego3,
          description:
            "Naše platforma dělá z učení jazyka zábavu a zážitek, takže budete motivováni zůstat u toho.",
        },
      ],
    },
    mission: {
      config: [
        {
          title: "Personalizace a přístup",
          description:
            "Náš výkonný algoritmus strojového učení neustále pracuje na poskytování učebních materiálů na správné úrovni pro každého jednotlivce. lexicos umožňuje studentům, aby si sami našli vzorce, aniž by se museli soustředit na pravidla jazyka – stejným způsobem, jakým jste se jako děti naučili svůj mateřský jazyk.",
          image: zdjecie1,
        },
        {
          title: "Odvážná kombinace technologie a vědy",
          description:
            "Náš výkonný algoritmus strojového učení neustále pracuje na poskytování učebních materiálů na správné úrovni pro každého jednotlivce. lexicos umožňuje studentům, aby si sami našli vzorce, aniž by se museli soustředit na pravidla jazyka – stejným způsobem, jakým jste se jako děti naučili svůj mateřský jazyk.",
          image: zdjecie2,
        },
        {
          title: "Komunita a hodnoty",
          description:
            "Jazyky pomáhají lidem se zapojovat. Studium jazyků podporuje porozumění. Komunita platformy lexicos sdružuje lidi z celého světa. Jsme ambiciózní a stále posouváme hranice. Věříme, že učení jazyků by mělo být zábavné a jsme zvědaví na objevování nových kultur prostřednictvím jazyků.            ",
          image: zdjecie3,
        },
      ],
    },
  },
  formSender: {
    formControll: {
      titleUp: "Objednat",
      titleDown: "kurz",
      pageLink: "https://granty2050.eu/typ",
      title: "Máte zájem o kurz?",
      title1: "Chcete získat více informací?",
      subtitle: "Zanechte nám své číslo –",
      subtitle2: "zavoláme vám zpět",
      button1: "Jméno a příjmení",
      button2: "Telefonní číslo",
      button3: "E-mail",
      buttonSub: "Žádost o zavolání",
      checkOne:
        "Odesláním tohoto formuláře souhlasím s podmínkami a prohlašuji, že jsem si přečetl/a",
      checkOneTwo: "a",
      checkTwo: "Souhlasím s kontaktováním pro marketingové účely",
      textOne: "Informujeme vás, že:",
      textTwo: `Správcem vašich osobních údajů je EOU Sp. z o.o. se sídlem ve Varšavě, Młynarska 42 / 115, 01-171, zapsaná v rejstříku podnikatelů vedeném Okresním soudem ve Varšavě pod číslem KRS 0001038089, NIP 5273057993, REGON: 525396900. Vaše osobní údaje budou zpracovávány v souvislosti s vyřízením vašeho dotazu v kontaktním formuláři nebo s plněním uzavřené smlouvy. Tím, že nás kontaktujete, dobrovolně souhlasíte se zpracováním svých osobních údajů pro výše uvedené účely. V souvislosti s naším zpracováním vašich údajů máte právo na přístup, opravu, vymazání nebo omezení zpracování, právo vznést námitku proti zpracování a právo na přenositelnost údajů. Máte také právo svůj souhlas kdykoli odvolat, ale odvolání souhlasu nemá vliv na zákonnost zpracování provedeného na základě souhlasu před jeho odvoláním. Máte také právo podat stížnost u dozorového úřadu. Poskytnutí údajů je samozřejmě dobrovolné, ale může být nezbytné pro vyřízení dotazu nebo v souvislosti s plněním smlouvy. Osobní údaje budou uchovávány po dobu nezbytnou k výše uvedeným účelům, nikoli však po dobu promlčení případných nároků podle obecných právních předpisů. Pečlivě si přečtěte zásady ochrany osobních údajů.`,
      buttonMore: "Přečtěte si viac",
      buttonLess: "Přečtěte si méně",
      subOne: "podmienky",
      subTwo: "a pravidlá ochrany údajov.",
      buttonTXT: "Děkujeme",
      slovakLead: "cz",
      buttonText:
        "Jeden z našich konzultantů se vám ozve během několika následujících pracovních dnů.",
      buttonText2: "Budeme v kontaktu!",
      privacyLink: "/Privacy_CZ.pdf",
      rulesLink: "/Regulations_CZ.pdf",
      product: "ec",
      market: "cz",
      send_to_url: "https://crm.europa-edu.eu/api/v1/cms/create_order",
      utm_source: "Platform_Lexicos",
      product_variant: "cs-en",
      server_link: "https://cms.europa-assets.eu/integration/lead-from-landing",
      currency: "czk",
      price: 990,
    },
  },
  footer: {
    privacy: "Ochrana údajů",
    privacyLink: "/Privacy_CZ.pdf",
    rules: "Podmínky služby",
    rulesLink: "/Regulations_CZ.pdf",
  },
};
