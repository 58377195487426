import screen1 from "../images/newProductPage/italian/1.png";

import Ildiko from "../images/newProductPage/hungarian/people/Ildiko.png";
import Katalin from "../images/newProductPage/hungarian/people/Katalin.png";
import Istvan from "../images/newProductPage/hungarian/people/Istvan.png";
import Zoltan from "../images/newProductPage/hungarian/people/Zoltan.png";
import Gabor from "../images/newProductPage/hungarian/people/Gabor.png";
import Maria from "../images/newProductPage/hungarian/people/Maria.png";

import new_england from "../images/newProductPage/languages/UK.png";
import new_french from "../images/newProductPage/languages/FR.png";
import new_spanish from "../images/newProductPage/languages/ES.png";
import new_niderland from "../images/newProductPage/languages/ND.png";
import new_germany from "../images/newProductPage/languages/DE.png";
import new_italy from "../images/newProductPage/languages/IT.png";
import new_russia from "../images/newProductPage/languages/RU.png";
import new_portugal from "../images/newProductPage/languages/PR.png";
import new_polish from "../images/newProductPage/languages/PL.png";

import learning1 from "../images/learning/nauka_ikona1.png";
import learning2 from "../images/learning/nauka_ikona2.png";
import learning3 from "../images/learning/nauka_ikona3.png";

import mockup from "../images/mockup_cz.png";

import dlaczego1 from "../images/newProductPage/why/dlaczego_1.webp";
import dlaczego2 from "../images/newProductPage/why/dlaczego_2.webp";
import dlaczego3 from "../images/newProductPage/why/dlaczego_3.webp";

import zdjecie1 from "../images/newProductPage/mission/women_square.png";
import zdjecie2 from "../images/newProductPage/mission/latop_square.png";
import zdjecie3 from "../images/newProductPage/mission/people_square.png";

export const slovenian = {
  navbar: {
    data: [
      { title: "Naroči se na tečaj", path: "/course" },
      { title: "Prijava", path: "" },
      { title: "O platformi lexicos", path: "/product" },
      { title: "O nas", path: "/about" },
    ],
  },
  mainPage: {
    product_page: {
      hero: {
        languages: [
          "angleško",
          "špansko",
          "nizozemsko",
          "nemško",
          "italijansko",
          "rusko",
          "portugalsko",
          "poljsko",
        ],
        title: "Govorite",
        prefix: "",
        sufix: "z zaupanjem!",
        subtitle: `Hitro pridobite samozavest s tečaji, osredotočenimi na pogovore iz vsakdanjega življenja.`,
        buttonText: "Naroči zdaj!",
      },
      labelInfo: {
        number: "9",
        numberDescription: "jezikov na voljo",
        itemTwo: "14 000 ocen",
        itemThree: {
          top: "Več kot 200k",
          bottom: "študentov",
        },
      },
      learning: {
        title: "Kako poteka tečaj?",
        config: [
          {
            category: "Lekcije iz resničnega življenja",
            description:
              "Vsaka stopnja učenja je sestavljena iz več deset lekcij. Če ne veste, na kateri stopnji ste, začnite z osnovami. Naučite se pozdravov, dni v tednu, kako naročiti hrano v restavraciji. Vsaka lekcija vključuje primere iz vsakdanjega življenja.",
          },
          {
            category: "Vaje",
            description:
              "Ne glede na to, ali vsakodnevno rešujete križanke ali se jim raje izogibate, je nemogoče zanikati, da te miselne uganke odlično trenirajo spomin in omogočajo ponavljanje naučenega.",
          },
          {
            category: "Besede, fraze in izrazi",
            description:
              "Vsaka lekcija je opremljena s skrbno izbranimi slikami, ki omogočajo intuitivno in kontekstualno učenje. Najpogosteje uporabljene teme zajemajo širok spekter besedišča iz vsakdanjega življenja in potovanj, kar omogoča komunikacijo brez t. i. jezikovnih blokad.",
          },
          {
            category: "Vprašajte strokovnjaka",
            description:
              "Imate težave z jezikom ali potrebujete pomoč pri vajah? Tu smo, da vam pomagamo! Naša funkcija 'Vprašajte strokovnjaka' vam omogoča, da se kadarkoli obrnete na strokovnjaka za jezike, ko potrebujete pomoč.",
          },
        ],
        photoData: [screen1],
      },
      languages: {
        languages: [
          { image: new_england, lang: "Angleščina" },
          { image: new_french, lang: "Francoščina" },
          { image: new_spanish, lang: "Španščina" },
          { image: new_niderland, lang: "Nizozemščina" },
          { image: new_germany, lang: "Nemščina" },
          { image: new_italy, lang: "Italijanščina" },
          { image: new_russia, lang: "Ruščina" },
          { image: new_portugal, lang: "Portugalščina" },
          { image: new_polish, lang: "Poljščina" },
        ],
        title: "Razpoložljivi jeziki:",
        description: `Pripravite se na naslednje potovanje, dopolnite svoje jezikovno znanje za življenjepis ali se poglobite v novo kulturo: raziščite obsežno zbirko lekcij lexicos in se naučite vsega, kar potrebujete za začetek pogovora, ne glede na raven vašega znanja.`,
      },
      success: {
        title: "Uspešne zgodbe",
        subtitle: `Oglejte si, kako so drugi izboljšali svoje znanje z lexicos`,
        config: [
          {
            title: "Praktične lekcije",
            name: "Katarina",
            comment: `Ne glede na to, ali ste začetnik, ki se šele spoznava z osnovami, ali napredni študent, ki želi razširiti svoje besedišče, lexicos ponuja nekaj za vsakogar. Ena stvar, ki mi je na tej platformi zelo všeč, je raznolikost lekcij – od naročanja hrane v restavraciji do klica taksija, zaradi česar je učenje realistično in praktično.`,
            photo: Ildiko,
          },
          {
            title: "Praktične lekcije",
            name: "Mirka",
            comment: `Všeč mi je, da so lekcije lexicos prilagojene resničnim situacijam, kar mi olajša uporabo naučenega v resničnem svetu. Po treh mesecih učenja sem dobila novo zaposlitev!`,
            photo: Katalin,
          },
          {
            title: "Zanimiva platforma",
            name: "Jakob",
            comment: `Lexicos je odlična platforma za učenje jezikov. Zaradi raznolikosti lekcij in vaj je učenje zanimivo in privlačno. S pomočjo učenja jezika sem preko interneta spoznal dekle iz Nemčije, s katero se kmalu poročiva. Priporočam 😊`,
            photo: Istvan,
          },
          {
            title: "Uporabljam v službi!",
            name: "Eva",
            comment: `Platformo lexicos uporabljam že nekaj mesecev in lahko z gotovostjo rečem, da mi je spremenila življenje. V službi sem bil napredovan! Vedno sem si želel naučiti novega jezika, vendar nikoli nisem imel časa ali sredstev. Z lexicos se lahko učim v svojem ritmu in urniku.`,
            photo: Zoltan,
          },
          {
            title: "Prijetno učenje",
            name: "Jure",
            comment: `Po mojem mnenju je lexicos čudovita platforma za vse, ki želijo izboljšati svoje jezikovne sposobnosti. Platforma je zasnovana tako, da je učenje novega jezika prijetno.`,
            photo: Gabor,
          },
          {
            title: "Uporabljam že nekaj mesecev",
            name: "Martina",
            comment: `Lexicos uporabljam že nekaj mesecev in moram reči, da je spremenil pravila igre. Kot oseba, ki jo je vedno zanimalo učenje novega jezika, a nikoli ni imela priložnosti, sem z lexicos lahko enostavno in udobno študirala. Sedaj razumem ljudi iz različnih kultur in se z njimi lahko sporazumevam.`,
            photo: Maria,
          },
        ],
      },
    },
    course: {
      title: "Kako izgleda tečaj?",
      mockuplexicos: mockup,
      right:
        "Na platformi lexicos ne boste našli dolgočasnih, suhoparnih slovničnih pravil, ki bi si jih morali zapomniti čez noč. Naučili se boste stavke in izraze, kot se uporabljajo v vsakdanjem življenju, nato pa jih utrdili s preprostimi vajami in ponavljanjem. Tečaj je zasnovan tako, da je učenje prijetno. Med tečajem imate možnost uporabljati:",
      right2Title: "Lekcije iz resničnega življenja",
      right2Bottom:
        "Vsaka stopnja učenja je sestavljena iz več deset lekcij. Če ne veste, na kateri stopnji ste, začnite z osnovami. Naučite se pozdravov, dni v tednu, kako naročiti hrano v restavraciji ipd. Vsaka lekcija vključuje primere iz vsakdanjega življenja.",
      commentBlock: {
        title: "Kaj pravijo uporabniki:",
        list: [
          {
            name: "Igor",
            comment: `Najboljša stvar pri lexicos je, da se ne učite le besede ali fraze, temveč tudi, kako te fraze uporabiti v kontekstu. To je razlika, zaradi katere je ta platforma tako uporabna.`,
          },
          {
            name: "Milena",
            comment: `Menim, da je lexicos odlična platforma za učenje jezika. Lahko se učite v svojem tempu, kar mi zelo ustreza.`,
          },
        ],
      },
    },
  },
  learningWithlexicos: {
    title: "Učenje z lexicos je:",
    cards: [
      {
        icon: learning1,
        headline: "Vrhunska kakovost",
        text: "Pri razvoju lexicos se je strokovna ekipa osredotočila na en najpomembnejši cilj: omogočiti obvladovanje tujega jezika v najkrajšem možnem času (ne glede na starost študenta!).",
      },
      {
        icon: learning2,
        headline: "Lekcije brez odhoda od doma",
        text: "Za uporabo aplikacije lexicos za učenje ne potrebujete nič drugega kot telefon, računalnik ali tablico. Z lexicos se lahko učite kjerkoli: v vrsti, na avtobusu in predvsem doma, brez potrebe po odhodu od doma.",
        text2:
          "Učenje z lexicos vam prihrani veliko časa in omogoča, da ga enostavno vključite v svoj urnik!",
      },
      {
        icon: learning3,
        headline: "Kratke, a sistematične lekcije",
        text: "Pri učenju jezika je najpomembnejša rednost. Hkrati je težko najti čas za učenje. Zavedajoč se tega je bila platforma zasnovana tako, da poteka učenje v kratkih, a sistematičnih lekcijah.",
      },
    ],
  },
  whylexicos: {
    title: "Zakaj lexicos?",
    textUp:
      "To je platforma z večstranskim pristopom k učenju, ki vam omogoča obvladovanje novega jezika na enak način, kot ste se naučili materinščine – naravno.",
    textBottom:
      "Učenje tujega jezika lahko spremeni življenje. Ne samo, da vam odpira vrata v svet poln poklicnih in osebnih priložnosti, ampak tudi razvija um in krepi samozavest. Zaradi tehnologije, kot je internet, postaja učenje jezikov bistveno za našo globalno interakcijo in uspeh.",
    cards: [
      {
        headline:
          "Naučite se fraz in izrazov iz resničnega življenja z našo napredno tehnologijo učenja.",
        text: "S pomočjo tisočev grafičnih in zvočnih materialov boste videli in slišali, kot domačini.",
      },
      {
        headline:
          "Pridobite samozavest pri govorjenju. Pravi cilj učenja jezika je biti razumljiv.",
        text: "S pomočjo lexicos se preprosto ponovite besedišče, ki ste ga slišali – ne bojte se govoriti! Naš sistem vas bo popravil.",
      },
      {
        headline:
          "Ne pustite, da vam besede uhajajo iz spomina. Učite se z različnimi vajami.",
        text: "Elemente aplikacije smo skrbno zasnovali tako, da se vaš um nikoli ne dolgočasi. Stalno pripravljamo in dodajamo nove izzive.",
      },
      {
        headline:
          "Potopite se v tuji jezik. Poskusite popolnoma razumeti nov jezik na osnovi konteksta.",
        text: "S pomočjo tisočev grafičnih in zvočnih materialov boste videli in slišali, kot domačini.",
      },
    ],
  },
  aboutUs: {
    hero: {
      title: "Poslanstvo",
      description:
        "Naš cilj je omogočiti vsakomur dostop do osebnega učitelja preko tehnologije. Verjamemo, da je jezik most med kulturami, in naše poslanstvo je premagovati komunikacijske ovire ter zbliževati ljudi skozi jezikovno izobraževanje.",
    },
    why: {
      title: "Zakaj mi?",
      config: [
        {
          title: "Inovativne metode učenja",
          image: dlaczego1,
          description:
            "Naš algoritem strojnega učenja prilagaja materiale stopnji posameznika, kar zagotavlja učinkovito in prilagojeno učenje.",
        },
        {
          title: "Široka izbira jezikov",
          image: dlaczego2,
          description:
            "Ponujamo tečaje najbolj uporabljenih svetovnih jezikov. To vam omogoča, da izberete jezik glede na svoje potrebe.",
        },
        {
          title: "Izobraževanje skozi zabavo",
          image: dlaczego3,
          description:
            "Naša platforma naredi učenje jezika zabavno in doživetje, kar vas motivira, da ostanete zvesti učenju.",
        },
      ],
    },
    mission: {
      config: [
        {
          title: "Personalizacija in dostopnost",
          description:
            "Naš zmogljiv algoritem strojnega učenja neprestano prilagaja učne materiale ustrezni stopnji posameznika.",
          image: zdjecie1,
        },
        {
          title: "Drzna kombinacija tehnologije in znanosti",
          description:
            "Z uporabo sodobne tehnologije omogočamo enostaven in učinkovit način učenja jezika.",
          image: zdjecie2,
        },
        {
          title: "Skupnost in vrednote",
          description:
            "Jeziki povezujejo ljudi. Učenje jezikov omogoča razumevanje. Skupnost platforme lexicos združuje ljudi z vsega sveta.",
          image: zdjecie3,
        },
      ],
    },
  },
  formSender: {
    formControll: {
      titleUp: "Naroči",
      titleDown: "tečaj",
      pageLink: "https://granty2050.eu/typ",
      title: "Vas zanima tečaj?",
      title1: "Želite izvedeti več informacij?",
      subtitle: "Pustite nam svojo številko –",
      subtitle2: "poklicali vas bomo nazaj",
      button1: "Ime in priimek",
      button2: "Telefonska številka",
      button3: "E-pošta",
      buttonSub: "Zahteva za klic",
      checkOne:
        "S pošiljanjem tega obrazca se strinjam s pogoji in potrjujem, da sem prebral/a",
      checkOneTwo: "in",
      checkTwo: "Strinjam se s kontaktiranjem za marketinške namene",
      textOne: "Obveščamo vas, da:",
      textTwo:
        "Upravljavec vaših osebnih podatkov je EOU Sp. z o.o., s sedežem v Varšavi, Młynarska 42 / 115, 01-171, registriran v registru podjetnikov pri okrožnem sodišču v Varšavi.",
      buttonMore: "Preberite več",
      buttonLess: "Preberite manj",
      subOne: "pogoji",
      subTwo: "in pravila zasebnosti.",
      buttonTXT: "Hvala",
      buttonText:
        "Eden od naših svetovalcev vas bo poklical v naslednjih delovnih dneh.",
      buttonText2: "Se slišimo!",
      privacyLink: "/Privacy_SI.pdf",
      rulesLink: "/Regulations_SI.pdf",
      product: "es",
      market: "si",
      send_to_url: "https://crm.europa-edu.eu/api/v1/cms/create_order",
      utm_source: "Platform_Lexicos",
      product_variant: "si-en",
      server_link: "https://cms.europa-assets.eu/integration/lead-from-landing",
      currency: "eur",
      price: 57,
    },
  },
  footer: {
    privacy: "Zasebnost",
    privacyLink: "/Privacy_SI.pdf",
    rules: "Pogoji storitve",
    rulesLink: "/Regulations_SI.pdf",
  },
};
