import screen1 from "../images/newProductPage/italian/1.png";
import screen2 from "../images/newProductPage/italian/2.png";
import screen5 from "../images/newProductPage/italian/5.png";
import screen6 from "../images/newProductPage/italian/6.png";
import screen7 from "../images/newProductPage/italian/7.png";

import Ildiko from "../images/newProductPage/hungarian/people/Ildiko.png";
import Katalin from "../images/newProductPage/hungarian/people/Katalin.png";
import Istvan from "../images/newProductPage/hungarian/people/Istvan.png";
import Zoltan from "../images/newProductPage/hungarian/people/Zoltan.png";
import Gabor from "../images/newProductPage/hungarian/people/Gabor.png";
import Maria from "../images/newProductPage/hungarian/people/Maria.png";

import new_england from "../images/newProductPage/languages/UK.png";
import new_french from "../images/newProductPage/languages/FR.png";
import new_spanish from "../images/newProductPage/languages/ES.png";
import new_niderland from "../images/newProductPage/languages/ND.png";
import new_germany from "../images/newProductPage/languages/DE.png";
import new_italy from "../images/newProductPage/languages/IT.png";
import new_russia from "../images/newProductPage/languages/RU.png";
import new_portugal from "../images/newProductPage/languages/PR.png";
import new_polish from "../images/newProductPage/languages/PL.png";

import learning1 from "../images/learning/nauka_ikona1.png";
import learning2 from "../images/learning/nauka_ikona2.png";
import learning3 from "../images/learning/nauka_ikona3.png";

import mockup from "../images/mockup_cz.png";

import dlaczego1 from "../images/newProductPage/why/dlaczego_1.webp";
import dlaczego2 from "../images/newProductPage/why/dlaczego_2.webp";
import dlaczego3 from "../images/newProductPage/why/dlaczego_3.webp";

import zdjecie1 from "../images/newProductPage/mission/women_square.png";
import zdjecie2 from "../images/newProductPage/mission/latop_square.png";
import zdjecie3 from "../images/newProductPage/mission/people_square.png";

export const italian = {
  navbar: {
    data: [
      { title: "Ordina un Corso", path: "/course" },
      { title: "Accedi", path: "/start" },
      { title: "Lexicos", path: "/" },
      { title: "Chi Siamo", path: "/about" },
    ],
  },
  mainPage: {
    product_page: {
      hero: {
        languages: [
          "in Inglese!",
          "in Spagnolo!",
          "in Tedesco!",
          "in Olandese!",
          "in Italiano!",
          "in Russo!",
          "in Polacco!",
          "in Francese!",
          "in Portoghese!",
        ],
        title: "Parla con più audacia,",
        prefix: "con fiducia",
        subtitle: `Acquisisci competenze linguistiche attraverso il nostro corso basato su situazioni di vita reale!`,
        buttonText: "Ordina Ora!",
      },
      labelInfo: {
        number: "9",
        numberDescription: "lingue",
        itemTwo: "114 000 recensioni",
        itemThree: {
          top: "Più di 200 mila",
          bottom: "studenti",
        },
      },
      learning: {
        title: "Com'è il corso?",
        config: [
          {
            category: "Impara la lingua nel modo più semplice",
            description:
              "Centinaia di lezioni con esempi linguistici usati in conversazioni realiEspressioni pratiche insegnate in corsi progettati da madrelinguaEsercizi che insegnano efficacemente abilità di lettura, ascolto e parlato",
          },
          {
            category: "Lezioni pratiche",
            description:
              "Imparare a tutti i livelli consiste in decine di lezioni. Se non conosci il tuo livello, inizia con le basi. Impara i saluti, i giorni della settimana, come ordinare cibo in un ristorante. Ogni lezione contiene esempi della vita quotidiana.",
          },
          {
            category: "Compiti",
            description:
              "Indipendentemente dal fatto che ti piaccia risolvere cruciverba quotidianamente o, al contrario, preferisci evitare tali attività, è innegabile che questi puzzle siano eccellenti per sviluppare la memoria e permettono di ripetere il materiale appreso.",
          },
          {
            category: "Parole, detti ed espressioni",
            description:
              "Ogni lezione è accompagnata da immagini accuratamente selezionate che rendono il processo di apprendimento intuitivo e contestuale. Gli argomenti più comunemente usati coprono un'ampia gamma di vocabolario dalla vita quotidiana e dai viaggi, permettendoti di comunicare senza barriere linguistiche in questi ambiti.",
          },
        ],
        photoData: [screen1, screen2, screen5, screen6, screen7],
      },
      languages: {
        languages: [
          { image: new_england, lang: "Inglese" },
          { image: new_french, lang: "Francese" },
          { image: new_spanish, lang: "Spagnolo" },
          { image: new_niderland, lang: "Olandese" },
          { image: new_germany, lang: "Tedesco" },
          { image: new_italy, lang: "Italiano" },
          { image: new_russia, lang: "Russo" },
          { image: new_portugal, lang: "Portoghese" },
          { image: new_polish, lang: "Polacco" },
        ],
        title: "Lingue disponibili:",
        description: ` Preparati per il tuo prossimo viaggio, aggiungi competenze linguistiche al tuo curriculum o immergiti in una nuova cultura: scopri l'ampia collezione di lezioni Lexicos e impara ciò di cui hai bisogno per iniziare una conversazione, indipendentemente dal tuo livello di esperienza.`,
      },
      success: {
        title: "Storie di successo",
        subtitle: ` Scopri come gli altri parlano fluentemente utilizzando Lexicos`,
        config: [
          {
            title: "Lezioni pratiche",
            name: "Ildikó",
            comment: `Che tu sia un principiante che inizia con le basi o un avanzato che cerca di espandere il tuo vocabolario, Lexicos offre qualcosa per tutti. Una cosa che adoro di questa piattaforma è la varietà di lezioni, dal cenare in un ristorante al chiamare un taxi, rendendo l'esperienza di apprendimento realistica e pratica. Inoltre, le espressioni pratiche e le registrazioni che dimostrano la corretta pronuncia sono un grande vantaggio.`,
            photo: Ildiko,
          },
          {
            title: "Lezioni pratiche",
            name: "Katalin",
            comment: `doro come le lezioni di Lexicos siano adattate a situazioni reali, rendendo facile applicare ciò che ho imparato nella realtà. Dopo 3 mesi di studio, mi è stato offerto un nuovo lavoro!,
photo: Katalin`,
            photo: Katalin,
          },
          {
            title: "Piattaforma interessante",
            name: "István",
            comment: `Lexicos è un'eccellente piattaforma per l'apprendimento delle lingue. La varietà di lezioni ed esercizi rende il processo di apprendimento interessante e attraente. Grazie all'apprendimento della lingua, ho incontrato una ragazza tedesca online, e ci sposiamo presto. Lo consiglio.`,
            photo: Istvan,
          },
          {
            title: "Lo uso al lavoro!",
            name: "Zoltán",
            comment: `A mio parere, Lexicos è una piattaforma meravigliosa per chiunque cerchi di migliorare le proprie competenze linguistiche. La piattaforma è progettata per rendere l'apprendimento di una nuova lingua un'esperienza piacevole.`,
            photo: Zoltan,
          },
          {
            title: "Apprendimento piacevole",
            name: "Gábor",
            comment: `Uso Lexicos da diversi mesi ormai, e posso dire con sicurezza che ha cambiato la mia vita. Sono stato promosso al lavoro! Sono sempre stato interessato a padroneggiare una nuova lingua, ma non ho mai avuto il tempo o le risorse. Con Lexicos, posso imparare al mio ritmo e nel mio orario.`,
            photo: Gabor,
          },
          {
            title: "Lo uso da qualche mese.",
            name: "Mária",
            comment: `Uso Lexicos da diversi mesi ormai, e devo dire che ha portato un grande cambiamento per me. Sono sempre stato interessato a padroneggiare una nuova lingua, ma non ho mai avuto l'opportunità, ma Lexicos ha reso l'apprendimento così semplice e confortevole per me. Oggi, capisco e comunico con persone di diverse culture, il che ha ampliato i miei orizzonti e aperto nuove opportunità per me.`,
            photo: Maria,
          },
        ],
      },
    },
    course: {
      title: "Com'è un corso?",
      right:
        "Sulla piattaforma Lexicos, non troverai regole grammaticali noiose e secche che devi imparare in un giorno. Impari frasi ed espressioni utilizzate nella vita quotidiana, poi le consolidi nella memoria con semplici esercizi e ripetizioni. L'obiettivo del corso è rendere l'apprendimento divertente.",
      right2Title: "Lezioni reali",
      right2Bottom:
        "L'apprendimento a ogni livello consiste in decine di lezioni. Se non conosci il tuo livello - inizia con le basi. Impara i saluti, i giorni della settimana, i tuoi sentimenti. Impara come ordinare cibo in un ristorante, e come ordinare un taxi. Ogni lezione include esempi dalla vita quotidiana. Ogni lezione è un po' diversa, composta da vari compiti ed esercizi, ma ogni forma un tutto - così puoi facilmente padroneggiare la lingua. Puoi iniziare la lezione in qualsiasi momento.",
      flexDown: [
        {
          title: "Durante l'apprendimento, incontrerai elementi come:",
          text: `
      Audio recordings teaching correct pronunciation,
    `,
          text1: "Espressioni e frasi dalla vita reale,",
          text2: "Immagini che ti aiutano a associare visivamente ",
          text3: "e ricordare parole ed espressioni,",
          text4: "...e molti altri.",
        },
        {
          title: "Compiti",
          text: `Indipendentemente dal fatto che ti piaccia risolvere puzzle quotidianamente o, al contrario, preferisci evitare tali attività, è innegabile che questi puzzle siano eccellenti per sviluppare la memoria e permettono di ripetere il materiale appreso.`,
        },
        {
          title: "Ripetizioni",
          text: `Gli studenti più avanzati possono ripetere il vocabolario in modalità di apprendimento continuo e testare le loro conoscenze in ogni area tematica. Include tutto il vocabolario disponibile, da ogni categoria. Un algoritmo di apprendimento controlla costantemente il livello di conoscenza e adatta le lezioni successive di conseguenza.`,
        },
      ],
    },
    learningWithlexicos: {
      title: "Apprendimento sulla piattaforma linguistica Lexicos:",
      cards: [
        {
          icon: learning1,
          headline: `Alta Qualità`,
          text: `
          Durante lo sviluppo di Lexicos, un team di esperti ha stabilito uno degli obiettivi più importanti: rendere l'apprendimento di una lingua straniera il più veloce possibile con il nostro metodo (indipendentemente dall'età dell'apprendente!).
        `,
        },
        {
          icon: learning2,
          headline: `Lezioni senza lasciare casa`,
          text: `Per utilizzare l'app di apprendimento Lexicos, tutto ciò di cui hai bisogno è il tuo telefono, computer o tablet. Con Eureka, puoi imparare ovunque: in fila, sull'autobus e, soprattutto, a casa senza dover andare da nessuna parte. Imparare mentre fai il bagno o porti a spasso il cane? Con Lexicos, è possibile!
      `,
          text2:
            "Imparare con Lexicos risparmia molto tempo di pendolarismo e puoi facilmente integrare l'apprendimento nel tuo programma!",
        },
        {
          icon: learning3,
          headline: `Lezioni brevi, ma sistematiche`,
          text: `La cosa più importante nell'apprendimento delle lingue è la regolarità. Allo stesso tempo, è difficile per tutti noi trovare il tempo necessario. Con questo in mente, Eureka è progettata per insegnare in lezioni brevi ma sistematiche.`,
        },
      ],
    },
    whylexicos: {
      title: "Com'è un corso?",
      textUp:
        "Questa è una piattaforma di apprendimento multifacetica che ti aiuta a padroneggiare una nuova lingua in modo naturale come la tua lingua madre.",
      textBottom:
        "Imparare una lingua straniera può cambiare la tua vita. Non solo apre una finestra su un mondo pieno di opportunità professionali e personali, ma sviluppa anche la tua mente e aumenta la tua fiducia. Poiché tecnologie come Internet riducono le distanze e diventiamo membri di una comunità globale, la capacità di apprendere lingue diventa una parte essenziale della nostra interazione con il mondo e del nostro successo.",
      cards: [
        {
          headline: `Impara espressioni e frasi dalla vita quotidiana con la nostra tecnologia avanzata di apprendimento.,
          text: Le lezioni sono continuamente adattate al tuo livello, quindi non ti annoierai mai o perderai la motivazione.`,
        },
        {
          headline: `Immergiti in una lingua straniera. Cerca di capire appieno una nuova lingua basata sulla tua conoscenza contestuale.,
          text: Con migliaia di grafici e registrazioni audio, vedrai e sentirai come un madrelingua.`,
        },
        {
          headline: `Acquisisci fiducia nel parlare. Il vero obiettivo dell'apprendimento delle lingue è anche comprendere.,
          text: Con Lexicos, tutto ciò che devi fare è ripetere il vocabolario che senti - non avere paura di parlare! Il nostro sistema ti correggerà.`,
        },
        {
          headline: `Non lasciare che le parole scivolino via dalla tua memoria. Impara attraverso vari esercizi.`,
          text: `Abbiamo progettato con cura gli elementi dell'app in modo che la tua mente non si annoi mai. Stiamo costantemente preparando e ampliando Eureka con nuove sfide per rendere l'apprendimento divertente.`,
        },
      ],
    },
  },
  aboutUs: {
    hero: {
      title: "Mission",
      description: `Crediamo che la lingua formi un ponte tra le culture, e la nostra missione è superare le barriere comunicative e unire le persone attraverso l'educazione linguistica.`,
    },
    why: {
      title: "Perché Noi?",
      config: [
        {
          title: "Metodi Educativi Innovativi",
          image: dlaczego1,
          description:
            "Il nostro sofisticato e collaudato algoritmo di apprendimento adatta i materiali al tuo attuale livello di conoscenza e lo espande attraverso un insegnamento efficace.",
        },
        {
          title: "Ampia Gamma di Lingue",
          image: dlaczego2,
          description:
            "Offriamo corsi nelle lingue più popolari al mondo. Questo ti permette di scegliere la lingua che meglio si adatta alle tue esigenze.",
        },
        {
          title: "Educazione Divertente",
          image: dlaczego3,
          description:
            "La nostra piattaforma rende l'apprendimento delle lingue un'esperienza divertente e piacevole, così sarai motivato ad imparare le lingue.",
        },
      ],
    },
    mission: {
      config: [
        {
          title: "Personalizzazione e Accessibilità",
          description:
            "Il nostro efficiente algoritmo di apprendimento automatico lavora costantemente per fornire materiali di livello appropriato per ogni individuo. Lexicos consente agli apprendenti di scoprire modelli da soli senza dover concentrarsi sulle regole della lingua – proprio come hai imparato la tua lingua madre durante l'infanzia.",
          image: zdjecie1,
        },
        {
          title: "Combinazione di Tecnologia e Scienza",
          description:
            "Lexicos utilizza una combinazione audace di tecnologia e scienza per stimolare la mente all'azione. Ripetere innumerevoli liste di parole ed espressioni è noioso, e il tuo cervello lo sa. Il nostro metodo è eccitante ed efficace, costringendoti a usare la conoscenza acquisita in modi diversi in momenti specifici - il tuo cervello apprezzerà questa soluzione!",
          image: zdjecie2,
        },
        {
          title: "Comunità e Valori",
          description:
            "Le lingue aiutano le persone a impegnarsi. Imparare le lingue facilita la comprensione. La comunità Lexicos unisce persone da tutto il mondo. Siamo ambiziosi e spingiamo sempre i limiti. Crediamo che l'apprendimento delle lingue possa essere divertente e siamo curiosi di esplorare nuove culture attraverso la lingua.",
          image: zdjecie3,
        },
      ],
    },
  },
  formSender: {
    formControll: {
      pageLink: "https://nyelvfejlesztesi-kozpont.eu/",
      title: "Interessato al corso? ",
      title1: "Vorresti maggiori informazioni?",
      subtitle: "Lascia il tuo numero di telefono - ti richiameremo.",
      subtitle1: "Se hai già un account, compila i seguenti campi:",
      button1: "Nome e Cognome",
      button2: "Numero di Telefono",
      button3: "Email",
      buttonSub: "Invia",
      checkOne:
        "Inviando questo modulo, accetto i termini e dichiaro di aver letto quanto segue",
      checkOneTwo: "il",
      checkTwo: "Acconsento ad essere contattato per scopi di marketing",
      textOne: "Si prega di essere informati che",
      textTwo:
        "Il titolare dei tuoi dati personali è EOU Sp. z o.o., con sede a Varsavia, Młynarska 42 / 115, 01-171, registrata nel registro degli imprenditori tenuto dal tribunale distrettuale di Varsavia con KRS5273057993, REGON: 525396900, NIP5273057993. I tuoi dati personali saranno trattati in relazione alla richiesta del modulo di contatto o all'esecuzione del contratto concluso. Contattandoci, acconsenti volontariamente al trattamento dei tuoi dati personali per le finalità sopra menzionate. Hai il diritto di accedere, correggere, cancellare o limitare il trattamento dei tuoi dati, opporti al trattamento e alla portabilità dei dati. Hai anche il diritto di revocare il tuo consenso in qualsiasi momento, ma la revoca del consenso non pregiudica la liceità del trattamento basato sul consenso prima della revoca. Hai anche il diritto di presentare un reclamo all'autorità di controllo. Fornire i dati è volontario ma può essere necessario per rispondere a una richiesta o eseguire un contratto. I dati personali saranno conservati per il periodo necessario per le finalità sopra indicate, ma non oltre il periodo di prescrizione delle pretese ai sensi della legge generale. Si prega di leggere attentamente l'informativa sulla privacy",
      buttonMore: "Leggi di più",
      buttonLess: "Leggi meno",
      subOne: "termini",
      subTwo: "e regole sulla privacy.",
      buttonTXT: "Grazie",
      rulesLink: "/Regulations_EN.pdf",
      privacyLink: "/Privacy_EN.pdf",
      product: "ei",
      market: "it",
      send_to_url: "https://crm.europa-edu.eu/api/v1/cms/create_order",
      utm_source: "Platform_Lexicos",
      product_variant: "it-en",
      server_link: "https://cms.europa-assets.eu/integration/lead-from-landing",
      currency: "eur",
      price: 57,
    },
  },
  footer: {
    privacy: "Privacy",
    privacyLink: "/Privacy_EN.pdf",
    rules: "Termini di servizio",
    rulesLink: "/Regulations_EN.pdf",
  },
};
