import React from "react";
import styled from "styled-components";
import { Colors } from "../../../colors/Colors";
import top from "./images/top.svg";
import bottom from "./images/down.svg";

import phone from "../../../images/newProductPage/phone/phone_mockup.png";
import Slider from "react-slick";

const Wrapper = styled.section`
  background-color: ${Colors.gray};
  padding: 60px 0 60px 0;
  margin-top: -50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 600px) {
    margin-top: -100px;
  }
`;

const SecondSection = styled.div`
  display: flex;
  gap: 120px;
  align-items: center;
  max-width: 1200px;

  @media (max-width: 1200px) {
    gap: 60px;
  }

  @media (max-width: 1000px) {
    gap: 0px;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 0px;
  }
`;

const Title = styled.h1`
  color: ${Colors.lightGreen};
  font-size: 40px;
  margin-bottom: 20px;
  font-weight: 900 !important;

  @media (max-width: 600px) {
    font-size: 36px;
    text-align: center;
  }
`;

const PhoneImageWrapper = styled.div`
  width: 250px;
  position: relative;
`;

const ImageWrapper = styled.div`
  position: relative;
  padding: 0px;
  top: 23px;
  left: 12px;
  width: 220px;
  border-radius: 30px !important;
  transform: translateZ(0);
  overflow: hidden;
`;

const SecondSectionTitle = styled.div`
  display: flex;
  justify-content: center;
  padding: 0px 30px;
  margin-top: 40px;
  max-width: 1200px;

  @media (max-width: 600px) {
    width: 100%;
    text-align: center;
    margin-top: 120px;
    flex-direction: column;
  }
`;

const SecondTextWrapper = styled.div`
  color: white;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .element__wrapper:first-of-type {
    .description {
      display: flex;
    }

    img:first-of-type {
      display: flex;
    }

    img:first-of-type {
      display: flex;
    }

    img:last-of-type {
      display: none;
    }
  }

  @media (max-width: 600px) {
    max-width: 300px;
  }
`;

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  arrows: false,
};

const SliderMobile = styled.div`
  display: none;

  @media (max-width: 800px) {
    display: flex;
    justify-content: center;
    height: 500px;
    overflow: hidden;
    margin-bottom: 50px;
  }
`;

const SliderDestop = styled.div`
  width: 295px;
  height: 600px;
  display: flex;
  align-items: center;
  margin-top: -50px;
  overflow: hidden;

  @media (max-width: 800px) {
    display: none;
  }
`;

const ImageWrapperCase = styled.div`
  overflow: hidden;
  position: absolute;
  width: 240px;
`;

const ElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  max-width: 800px;

  :hover {
    p {
      display: flex;
    }

    img:first-of-type {
      display: flex;
    }

    img:last-of-type {
      display: none;
    }
  }
`;

const Category = styled.h1`
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 10px;
  color: ${Colors.mainColor};
  text-align: left;
  font-size: 24px;
  font-weight: 800 !important;

  img:first-of-type {
    display: none;
  }

  img:last-of-type {
    display: flex;
  }
`;

const Description = styled.p`
  color: ${Colors.mainColor};
  font-size: 16px !important;
  display: none;
  max-width: 500px;
  animation: 1s slide-top;

  @keyframes slide-top {
    from {
      margin-top: -100px;
    }

    to {
      margin-left: 0%;
    }
  }
`;

const Learning = ({ data }) => {
  return (
    <Wrapper>
      <SecondSectionTitle>
        <Title>{data.title}</Title>
      </SecondSectionTitle>
      <SecondSection>
        <SecondTextWrapper>
          {data.config.map((c, i) => (
            <ElementWrapper className="element__wrapper">
              <Category className="category">
                {c.category}
                <img alt="arrow-top" src={top} />
                <img alt="arrow-down" src={bottom} />
              </Category>
              <div
                style={{
                  maxWidth: "500px",
                  overflow: "hidden",
                }}
              >
                <Description className="description">
                  {c.description}
                </Description>
              </div>
            </ElementWrapper>
          ))}
        </SecondTextWrapper>
        <SliderMobile>
          <PhoneImageWrapper>
            <ImageWrapperCase>
              <img
                src={phone}
                alt="phone"
                style={{ width: "100%", height: "auto" }}
              />
            </ImageWrapperCase>
            <ImageWrapper>
              <div style={{ width: "217px", overflow: "hidden" }}>
                <Slider {...settings}>
                  {data?.photoData?.map((data, i) => (
                    <img
                      src={data}
                      alt="Mobile lexicos"
                      style={{ width: "100%", height: "auto" }}
                    />
                  ))}
                </Slider>
              </div>
            </ImageWrapper>
          </PhoneImageWrapper>
        </SliderMobile>
        <SliderDestop>
          <PhoneImageWrapper>
            <ImageWrapperCase>
              <img
                src={phone}
                alt="phone"
                style={{ width: "100%", height: "auto" }}
              />
            </ImageWrapperCase>
            <ImageWrapper>
              <div style={{ width: "217px", overflow: "hidden" }}>
                <Slider {...settings}>
                  {data?.photoData?.map((data, i) => (
                    <img
                      src={data}
                      alt="Mobile lexicos"
                      style={{ width: "100%", height: "auto" }}
                    />
                  ))}
                </Slider>
              </div>
            </ImageWrapper>
          </PhoneImageWrapper>
        </SliderDestop>
      </SecondSection>
    </Wrapper>
  );
};

export default Learning;
