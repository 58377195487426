import screen1 from "../images/newProductPage/polish/1.png";
import screen2 from "../images/newProductPage/polish/2.png";
import screen3 from "../images/newProductPage/polish/3.png";
import screen4 from "../images/newProductPage/polish/4.png";
import screen5 from "../images/newProductPage/polish/5.png";
import screen6 from "../images/newProductPage/polish/6.png";

import Ildiko from "../images/newProductPage/hungarian/people/Ildiko.png";
import Katalin from "../images/newProductPage/hungarian/people/Katalin.png";
import Istvan from "../images/newProductPage/hungarian/people/Istvan.png";
import Zoltan from "../images/newProductPage/hungarian/people/Zoltan.png";
import Gabor from "../images/newProductPage/hungarian/people/Gabor.png";
import Maria from "../images/newProductPage/hungarian/people/Maria.png";

import new_england from "../images/newProductPage/languages/UK.png";
import new_french from "../images/newProductPage/languages/FR.png";
import new_spanish from "../images/newProductPage/languages/ES.png";
import new_niderland from "../images/newProductPage/languages/ND.png";
import new_germany from "../images/newProductPage/languages/DE.png";
import new_italy from "../images/newProductPage/languages/IT.png";
import new_russia from "../images/newProductPage/languages/RU.png";
import new_portugal from "../images/newProductPage/languages/PR.png";
import new_polish from "../images/newProductPage/languages/PL.png";

import learning1 from "../images/learning/nauka_ikona1.png";
import learning2 from "../images/learning/nauka_ikona2.png";
import learning3 from "../images/learning/nauka_ikona3.png";

import dlaczego1 from "../images/newProductPage/why/dlaczego_1.webp";
import dlaczego2 from "../images/newProductPage/why/dlaczego_2.webp";
import dlaczego3 from "../images/newProductPage/why/dlaczego_3.webp";

import zdjecie1 from "../images/newProductPage/mission/women_square.png";
import zdjecie2 from "../images/newProductPage/mission/latop_square.png";
import zdjecie3 from "../images/newProductPage/mission/people_square.png";

export const polish = {
  navbar: {
    data: [
      { title: "Zamów kurs", path: "/course" },
      { title: "Zaloguj się", path: "/start" },
      { title: "Lexicos", path: "/" },
      { title: "O nas", path: "/about" },
    ],
  },
  mainPage: {
    product_page: {
      hero: {
        languages: [
          "po angielsku!",
          "po hiszpańsku!",
          "po niemiecku!",
          "po niderlandzku!",
          "po włosku!",
          "po rosyjsku!",
          "po polsku!",
          "po francusku!",
          "po portugalsku!",
        ],
        title: "Mów odważniej,",
        prefix: "lepiej",
        subtitle: `Zdobądź umiejętności językowe poprzez nasz kurs oparty na rzeczywistych sytuacjach!`,
        buttonText: "Zamów teraz!",
      },
      labelInfo: {
        number: "9",
        number: "9",
        numberDescription: "Języków",
        itemTwo: "14 000 ocen",
        itemThree: {
          top: "Ponad 200 000",
          bottom: "uczestników",
        },
      },
      learning: {
        title: "Jak wygląda kurs",
        config: [
          {
            category: "Ucz się języka w najprostszy sposób",
            description:
              "Setki lekcji z przykładami językowymi używanymi w prawdziwych rozmowach. Praktyczne zwroty nauczane przez native speakerów. Ćwiczenia efektywnie uczą czytania, słuchania i mówienia.",
          },
          {
            category: "Praktyczne lekcje",
            description:
              "Nauka na wszystkich poziomach składa się z dziesiątek lekcji. Jeśli nie znasz swojego poziomu – zacznij od podstaw. Naucz się powitań, dni tygodnia, jak zamówić jedzenie w restauracji. Każda lekcja zawiera przykłady z codziennych sytuacji.",
          },
          {
            category: "Zadania",
            description:
              "Niezależnie od tego, czy lubisz rozwiązywać codziennie krzyżówki, czy nie, nie można zaprzeczyć, że są one doskonałe dla rozwoju pamięci i umożliwiają powtarzanie opanowanego materiału.",
          },
          {
            category: "Słowa, zwroty i wyrażenia",
            description:
              "Każdej lekcji towarzyszą starannie dobrane obrazy, które ułatwiają intuicyjny i kontekstowy proces nauki. Najczęściej używane tematy obejmują szeroki zakres słownictwa z codziennego życia i podróży, dzięki czemu możesz komunikować się bez barier językowych.",
          },
        ],
        photoData: [screen1, screen2, screen3, screen4, screen5, screen6],
      },
      languages: {
        languages: [
          { image: new_england, lang: "Angielski" },
          { image: new_french, lang: "Francuski" },
          { image: new_spanish, lang: "Hiszpański" },
          { image: new_niderland, lang: "Niderlandzki" },
          { image: new_germany, lang: "Niemiecki" },
          { image: new_italy, lang: "Włoski" },
          { image: new_russia, lang: "Rosyjski" },
          { image: new_portugal, lang: "Portugalski" },
          { image: new_polish, lang: "Polski" },
        ],
        title: "Dostępne języki:",
        description: `Przygotuj się na swoją kolejną podróż, dodaj język do swojego CV lub zanurz się w nowej kulturze: odkryj bogatą kolekcję lekcji Lexicos i naucz się prowadzić rozmowy niezależnie od swojego poziomu zaawansowania.`,
      },
      success: {
        title: "Historie sukcesu",
        subtitle: `Zobacz, jak inni mówią płynnie dzięki Lexicos`,
        config: [
          {
            title: "Praktyczne lekcje",
            name: "Ildikó",
            comment: `Niezależnie od tego, czy jesteś początkujący i zaczynasz od podstaw, czy zaawansowany i chcesz poszerzyć swoje słownictwo, Lexicos ma coś dla każdego. Uwielbiam różnorodność lekcji, od zamawiania w restauracji po wołanie taksówki, co sprawia, że nauka jest realistyczna i praktyczna.`,
            photo: Ildiko,
          },
          {
            title: "Praktyczne lekcje",
            name: "Katalin",
            comment: `Uwielbiam to, jak lekcje Lexicos są dostosowane do prawdziwych sytuacji, dzięki czemu łatwo jest zastosować to, czego się nauczyłam, w rzeczywistości. Po 3 miesiącach nauki zaproponowano mi nową pracę!`,
            photo: Katalin,
          },
          {
            title: "Interesująca platforma",
            name: "István",
            comment: `Lexicos to doskonała platforma do nauki języków. Różnorodność lekcji i ćwiczeń sprawia, że proces nauki jest ciekawy i angażujący. Dzięki nauce języka poznałem dziewczynę z Niemiec online, a wkrótce się pobierzemy. Polecam.`,
            photo: Istvan,
          },
          {
            title: "Używam tego w pracy!",
            name: "Zoltán",
            comment: `Moim zdaniem Lexicos to wspaniała platforma dla wszystkich, którzy chcą poprawić swoje umiejętności językowe. Platforma jest zaprojektowana w taki sposób, że nauka nowego języka staje się przyjemnym doświadczeniem.`,
            photo: Zoltan,
          },
          {
            title: "Przyjemna nauka",
            name: "Gábor",
            comment: `Używam Lexicos od kilku miesięcy i mogę z pełnym przekonaniem powiedzieć, że zmieniło to moje życie. Awansowałem w pracy! Zawsze interesowałem się opanowaniem nowego języka, ale nigdy nie miałem na to czasu ani zasobów. Dzięki Lexicos mogę uczyć się we własnym tempie i według własnego harmonogramu.`,
            photo: Gabor,
          },
          {
            title: "Używam tego od kilku miesięcy.",
            name: "Mária",
            comment: `Używam Lexicos od kilku miesięcy i muszę powiedzieć, że przyniosło to wielką zmianę w moim życiu. Zawsze chciałam opanować nowy język, ale nigdy nie miałam okazji. Lexicos sprawiło, że nauka stała się łatwa i wygodna. Dziś rozumiem i komunikuję się z ludźmi z różnych kultur, co poszerzyło moje horyzonty i otworzyło nowe możliwości.`,
            photo: Maria,
          },
        ],
      },
      course: {
        title: "Jak wygląda kurs?",
        right:
          "Na platformie Lexicos nie znajdziesz nudnych, suchych zasad gramatyki, które trzeba opanować w jeden dzień. Uczysz się zdań i wyrażeń używanych na co dzień, a następnie utrwalasz je poprzez proste ćwiczenia i powtórki. Celem kursu jest, aby nauka była zabawą.",
        right2Title: "Prawdziwe lekcje",
        right2Bottom:
          "Nauka na każdym poziomie składa się z dziesiątek lekcji. Jeśli nie znasz swojego poziomu - zacznij od podstaw. Naucz się powitań, dni tygodnia, wyrażania swoich uczuć. Naucz się, jak zamówić jedzenie w restauracji i jak wezwać taksówkę. Każda lekcja zawiera przykłady z życia codziennego. Każda lekcja jest nieco inna i składa się z różnych zadań i ćwiczeń, ale wszystkie tworzą całość – dzięki czemu łatwo opanujesz język. Możesz rozpocząć lekcję w dowolnym momencie.",
        flexDown: [
          {
            title: "Podczas nauki napotkasz takie elementy, jak:",
            text: `
                Nagrania audio, które uczą poprawnej wymowy,
              `,
            text1: "Wyrażenia i zdania z prawdziwego życia,",
            text2: "Obrazy, które pomogą ci wizualnie kojarzyć",
            text3: "i zapamiętywać słowa i wyrażenia,",
            text4: "...i wiele innych.",
          },
          {
            title: "Zadania",
            text: `Niezależnie od tego, czy chcesz codziennie rozwiązywać zagadki, czy unikasz takich aktywności, nie da się zaprzeczyć, że zagadki te doskonale rozwijają pamięć i umożliwiają powtarzanie przyswojonego materiału.`,
          },
          {
            title: "Powtórki",
            text: `Zaawansowani uczniowie mogą powtarzać słownictwo w trybie ciągłego uczenia się i testować swoją wiedzę w każdej dziedzinie. Zawiera pełen dostępny zasób słownictwa z każdej kategorii. Algorytm nauki stale sprawdza poziom wiedzy i odpowiednio dostosowuje kolejne lekcje.`,
          },
        ],
      },
      learningWithlexicos: {
        title: "Nauka na platformie językowej Lexicos:",
        cards: [
          {
            icon: learning1,
            headline: `Wysoka jakość`,
            text: `
                    Podczas tworzenia Lexicos zespół ekspertów postawił sobie jeden z najważniejszych celów: sprawić, by nauka języka obcego naszą metodą była jak najszybsza (niezależnie od wieku uczącego się!).
                      `,
          },
          {
            icon: learning2,
            headline: `Lekcje bez wychodzenia z domu`,
            text: `Aby korzystać z aplikacji do nauki Lexicos, potrzebujesz jedynie telefonu, komputera lub tabletu. Z Lexicos możesz uczyć się wszędzie: w kolejce, w autobusie, a przede wszystkim w domu, bez konieczności wychodzenia. Nauka podczas kąpieli lub spaceru z psem? Z Lexicos to możliwe!
                    `,
            text2:
              "Nauka z Lexicos oszczędza dużo czasu na dojazdy, a naukę możesz łatwo wpasować w swój harmonogram!",
          },
          {
            icon: learning3,
            headline: `Krótkie, ale systematyczne lekcje`,
            text: `Najważniejsza w nauce języka jest regularność. Jednocześnie wszystkim nam trudno znaleźć wystarczająco dużo czasu. Dlatego Lexicos została zaprojektowana tak, aby uczyć w krótkich, ale systematycznych lekcjach.`,
          },
        ],
      },
      whylexicos: {
        title: "Jak wygląda kurs?",
        textUp:
          "To wszechstronna platforma edukacyjna, która pomoże ci opanować nowy język tak naturalnie, jak język ojczysty.",
        textBottom:
          "Nauka języka obcego może zmienić twoje życie. Otwiera nie tylko okno na świat pełen możliwości zawodowych i osobistych, ale także rozwija twój umysł i wzmacnia pewność siebie. Ponieważ technologie, takie jak Internet, zmniejszają dystanse i stajemy się członkami globalnej społeczności, umiejętność nauki języków staje się kluczowym elementem naszej interakcji ze światem i sukcesu.",
        cards: [
          {
            headline: `Ucz się wyrażeń i zdań z codziennego życia dzięki naszej zaawansowanej technologii nauki.`,
            text: `Lekcje są stale dostosowywane do twojego poziomu, dzięki czemu nigdy nie odczuwasz nudy ani utraty motywacji.`,
          },
          {
            headline: `Zanurz się w nowym języku. Spróbuj zrozumieć nowy język w pełni na podstawie swojego kontekstowego doświadczenia.`,
            text: `Dzięki tysiącom grafik i nagrań audio będziesz widzieć i słyszeć jak native speaker.`,
          },
          {
            headline: `Zyskaj pewność siebie w mówieniu. Prawdziwym celem nauki języka jest także rozumienie.`,
            text: `Z Lexicos musisz jedynie powtarzać słownictwo, które słyszysz - nie bój się mówić! Nasz system cię poprawi.`,
          },
          {
            headline: `Nie pozwól, by słowa uciekały ci z pamięci. Ucz się poprzez różnorodne ćwiczenia.`,
            text: `Starannie zaprojektowaliśmy elementy aplikacji tak, aby twój umysł nigdy się nie nudził. Stale przygotowujemy nowe wyzwania i rozwijamy Lexicos, aby nauka była przyjemnością.`,
          },
        ],
      },
    },
  },
  aboutUs: {
    hero: {
      title: "Misja",
      description: `Wierzymy, że język tworzy most między kulturami, a naszą misją jest przełamywanie barier komunikacyjnych i łączenie ludzi poprzez naukę języków.`,
    },
    why: {
      title: "Dlaczego my?",
      config: [
        {
          title: "Innowacyjne metody nauczania",
          image: dlaczego1,
          description:
            "Nasz zaawansowany i sprawdzony w praktyce algorytm uczenia się dostosowuje materiały do twojego aktualnego poziomu wiedzy i rozwija go poprzez efektywne nauczanie.",
        },
        {
          title: "Szeroka gama języków",
          image: dlaczego2,
          description:
            "Oferujemy kursy w najpopularniejszych językach na świecie. Dzięki temu możesz wybrać język, który odpowiada twoim potrzebom.",
        },
        {
          title: "Nauka w sposób zabawny",
          image: dlaczego3,
          description:
            "Nasza platforma sprawia, że nauka języków jest zabawnym i przyjemnym doświadczeniem, co motywuje cię do nauki.",
        },
      ],
    },
    mission: {
      config: [
        {
          title: "Personalizacja i dostępność",
          description:
            "Nasz wydajny algorytm uczenia maszynowego stale dostarcza odpowiednie materiały dla każdej osoby. Lexicos umożliwia uczniom samodzielne odkrywanie wzorców, bez skupiania się na zasadach gramatyki – tak jak w dzieciństwie nauczyłeś się swojego języka ojczystego.",
          image: zdjecie1,
        },
        {
          title: "Połączenie technologii i nauki",
          description:
            "Lexicos wykorzystuje odważne połączenie technologii i nauki, aby pobudzić umysł do działania. Ciągłe powtarzanie list słów i wyrażeń jest nudne, a twój mózg o tym wie. Nasza metoda jest ekscytująca i skuteczna, zmuszając cię do wykorzystania nabytej wiedzy na różne sposoby w określonych momentach – twój mózg doceni to rozwiązanie!",
          image: zdjecie2,
        },
        {
          title: "Społeczność i wartości",
          description:
            "Języki pomagają ludziom się zobowiązywać. Nauka języków ułatwia zrozumienie. Społeczność Lexicos łączy ludzi z całego świata. Jesteśmy ambitni i stale przekraczamy granice. Wierzymy, że nauka języków może być zabawna i jesteśmy ciekawi, jak poprzez język odkrywać nowe kultury.",
          image: zdjecie3,
        },
      ],
    },
  },
  formSender: {
    formControll: {
      pageLink: "https://best-science-facts.com/pl/",
      title: "Zainteresowany kursem?",
      title1: "Chcesz więcej informacji?",
      subtitle: "Zostaw swój numer telefonu - oddzwonimy do Ciebie.",
      subtitle1: "Jeśli masz już konto, wypełnij poniższe pola:",
      button1: "Imię i nazwisko",
      button2: "Numer telefonu",
      button3: "Email",
      buttonSub: "Wyślij",
      checkOne:
        "Przesyłając ten formularz, akceptuję warunki i oświadczam, że zapoznałem się z",
      checkOneTwo: "z",
      checkTwo: "Wyrażam zgodę na kontakt w celach marketingowych",
      textOne: "Proszę pamiętać, że",
      textTwo:
        "Administratorem Twoich danych osobowych jest EOU Sp. z o.o. z siedzibą w Warszawie, ul. Młynarska 42 / 115, 01-171, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy dla Warszawy pod numerem KRS 5273057993, REGON: 525396900, NIP 5273057993. Twoje dane osobowe będą przetwarzane w związku z zapytaniem z formularza kontaktowego lub wykonaniem zawartej umowy. Kontaktując się z nami, dobrowolnie wyrażasz zgodę na przetwarzanie Twoich danych osobowych w powyższych celach. Masz prawo dostępu do swoich danych, ich poprawiania, usunięcia lub ograniczenia przetwarzania, prawo do sprzeciwu wobec przetwarzania oraz prawo do przenoszenia danych. Masz również prawo do cofnięcia zgody w dowolnym momencie, jednak cofnięcie zgody nie wpływa na zgodność z prawem przetwarzania, które miało miejsce na podstawie zgody przed jej cofnięciem. Masz także prawo wniesienia skargi do organu nadzorczego. Podanie danych jest dobrowolne, ale może być konieczne do udzielenia odpowiedzi na zapytanie lub wykonania umowy. Dane osobowe będą przechowywane przez okres niezbędny do realizacji powyższych celów, nie dłużej niż okres przedawnienia zgodnie z obowiązującymi przepisami. Prosimy o dokładne zapoznanie się z polityką prywatności",
      buttonMore: "Czytaj więcej",
      buttonLess: "Czytaj mniej",
      subOne: "Warunki",
      subTwo: "i politykę prywatności.",
      buttonTXT: "Dziękujemy",
      rulesLink: "/Regulations_EN.pdf",
      privacyLink: "/Privacy_EN.pdf",
      product: "lpp",
      market: "pl",
      send_to_url: "https://crm.eou-edu.eu/api/v1/cms/create_order",
      utm_source: "Platform_Lexicos",
      product_variant: "pl-en",
      server_link: "https://cms.europa-assets.eu/integration/lead-from-landing",
      currency: "zł",
      price: 137,
    },
  },
  footer: {
    privacy: "Polityka prywatności",
    privacyLink: "/Privacy_EN.pdf",
    rules: "Warunki użytkowania",
    rulesLink: "/Regulations_EN.pdf",
  },
};
