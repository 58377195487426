import React from "react";
import { useParams } from "react-router";
import NewProductPage from "./product/NewProductPage";
import OldProductPage from "./product/OldProductPage";

const ProductPage = ({ data }) => {
  const { lang } = useParams();

  return (
    <div>
      {![
        "cz",
        "cs",
        "hu",
        "sk",
        "en",
        "hr",
        "lt",
        "de",
        "it",
        "pl",
        "sl",
        "si",
      ].includes(lang) ? (
        <OldProductPage data={data} />
      ) : (
        <NewProductPage data={data.product_page} />
      )}
    </div>
  );
};

export default ProductPage;
