import screen1 from "../images/newProductPage/italian/1.png";

import Ildiko from "../images/newProductPage/hungarian/people/Ildiko.png";
import Katalin from "../images/newProductPage/hungarian/people/Katalin.png";
import Istvan from "../images/newProductPage/hungarian/people/Istvan.png";
import Zoltan from "../images/newProductPage/hungarian/people/Zoltan.png";
import Gabor from "../images/newProductPage/hungarian/people/Gabor.png";
import Maria from "../images/newProductPage/hungarian/people/Maria.png";

import new_england from "../images/newProductPage/languages/UK.png";
import new_french from "../images/newProductPage/languages/FR.png";
import new_spanish from "../images/newProductPage/languages/ES.png";
import new_niderland from "../images/newProductPage/languages/ND.png";
import new_germany from "../images/newProductPage/languages/DE.png";
import new_italy from "../images/newProductPage/languages/IT.png";
import new_russia from "../images/newProductPage/languages/RU.png";
import new_portugal from "../images/newProductPage/languages/PR.png";
import new_polish from "../images/newProductPage/languages/PL.png";

import learning1 from "../images/learning/nauka_ikona1.png";
import learning2 from "../images/learning/nauka_ikona2.png";
import learning3 from "../images/learning/nauka_ikona3.png";

import mockup from "../images/mockup_cz.png";

import dlaczego1 from "../images/newProductPage/why/dlaczego_1.webp";
import dlaczego2 from "../images/newProductPage/why/dlaczego_2.webp";
import dlaczego3 from "../images/newProductPage/why/dlaczego_3.webp";

import zdjecie1 from "../images/newProductPage/mission/women_square.png";
import zdjecie2 from "../images/newProductPage/mission/latop_square.png";
import zdjecie3 from "../images/newProductPage/mission/people_square.png";

export const german = {
  navbar: {
    data: [
      { title: "Einen Kurs bestellen", path: "/course" },
      { title: "Einloggen", path: "/start" },
      { title: "Lexicos", path: "/" },
      { title: "Über uns", path: "/about" },
    ],
  },
  mainPage: {
    product_page: {
      hero: {
        languages: [
          "auf Englisch!",
          "auf Spanisch!",
          "auf Deutsch!",
          "auf Niederländisch!",
          "auf Italienisch!",
          "auf Russisch!",
          "auf Polnisch!",
          "auf Französisch!",
          "auf Portugiesisch!",
        ],
        title: "Sprich mutiger,",
        prefix: "besser",
        subtitle: `Erwerbe Sprachkenntnisse durch unseren Kurs, basierend auf realen Situationen!`,
        buttonText: "Jetzt bestellen!",
      },
      labelInfo: {
        number: "9",
        numberDescription: "Sprachen",
        itemTwo: "14.000 Bewertungen",
        itemThree: {
          top: "Mehr als 200.000",
          bottom: "Lernende",
        },
      },
      learning: {
        title: "What does the course look like?",
        config: [
          {
            category: "Learn language in the easiest way",
            description:
              "Hunderte von Lektionen mit Sprachbeispielen, die in echten Gesprächen verwendet werdenPraktische Ausdrücke, die in Kursen von Muttersprachlern unterrichtet werdenÜbungen lehren effektiv Lese-, Hör- und Sprechfähigkeiten",
          },
          {
            category: "Praktische Lektionen",
            description:
              "Das Lernen auf allen Ebenen besteht aus Dutzenden von Lektionen. Wenn du dein Niveau nicht kennst – beginne mit den Grundlagen. Lerne Begrüßungen, Wochentage, wie man Essen in einem Restaurant bestellt. Jede Lektion enthält Beispiele aus dem Alltag.",
          },
          {
            category: "Aufgaben",
            description:
              "Unabhängig davon, ob du täglich Kreuzworträtsel lösen möchtest oder solche Aktivitäten lieber vermeidest, ist es unbestreitbar, dass diese Rätsel hervorragend zur Entwicklung des Gedächtnisses geeignet sind und das Wiederholen des gelernten Materials ermöglichen.",
          },
          {
            category: "Wörter, Redewendungen und Ausdrücke",
            description:
              "Jede Lektion wird von sorgfältig ausgewählten Bildern begleitet, die den Lernprozess intuitiv und kontextuell machen. Die am häufigsten verwendeten Themen decken ein breites Spektrum an Vokabular aus dem Alltag und Reisen ab, soda ss du in diesen Bereichen ohne Sprachbarrieren kommunizieren kannst.",
          },
        ],
        photoData: [screen1],
      },
      languages: {
        languages: [
          { image: new_england, lang: "Englisch" },
          { image: new_french, lang: "Französisch" },
          { image: new_spanish, lang: "Spanisch" },
          { image: new_niderland, lang: "Niederländisch" },
          { image: new_germany, lang: "Deutsch" },
          { image: new_italy, lang: "Italienisch" },
          { image: new_russia, lang: "Russisch" },
          { image: new_portugal, lang: "Portugiesisch" },
          { image: new_polish, lang: "Polnisch" },
        ],
        title: "Verfügbare Sprachen:",
        description: `Bereite dich auf deine nächste Reise vor, füge deinem Lebenslauf Sprachkenntnisse hinzu oder tauche in eine neue Kultur ein: Entdecke die umfangreiche Sammlung von Lexicos-Lektionen und lerne, was du brauchst, um ein Gespräch zu beginnen, unabhängig von deinem Erfahrungsstand.`,
      },
      success: {
        title: "Erfolgsgeschichten",
        subtitle: `Sieh, wie andere mit Lexicos fließend sprechen`,
        config: [
          {
            title: "Praktische Lektionen",
            name: "Ildikó",
            comment: `Egal, ob du Anfänger bist und mit den Grundlagen beginnst oder fortgeschrittener Lernender bist und deinen Wortschatz erweitern möchtest, Lexicos bietet für jeden etwas. Was ich an dieser Plattform liebe, ist die Vielfalt der Lektionen, von der Bestellung in einem Restaurant bis zum Rufen eines Taxis, die das Lernen realistisch und praktisch machen. Außerdem sind die praktischen Ausdrücke und Aufnahmen, die die richtige Aussprache demonstrieren, ein großer Vorteil.`,
            photo: Ildiko,
          },
          {
            title: "Praktische Lektionen",
            name: "Katalin",
            comment: `Ich liebe es, wie die Lexicos-Lektionen auf reale Situationen zugeschnitten sind, sodass es leicht ist, das Gelernte in die Realität umzusetzen. Nach 3 Monaten Studium wurde mir ein neuer Job angeboten!`,
            photo: Katalin,
          },
          {
            title: "Interessante Plattform",
            name: "István",
            comment: `Lexicos ist eine ausgezeichnete Sprachlernplattform. Die Vielfalt der Lektionen und Übungen macht den Lernprozess interessant und ansprechend. Dank des Sprachlernens habe ich ein deutsches Mädchen online kennengelernt, und wir werden bald heiraten. Ich empfehle es.`,
            photo: Istvan,
          },
          {
            title: "Ich nutze es bei meiner Arbeit!",
            name: "Zoltán",
            comment: `Meiner Meinung nach ist Lexicos eine wunderbare Plattform für alle, die ihre Sprachkenntnisse verbessern möchten. Die Plattform ist so gestaltet, dass das Lernen einer neuen Sprache ein angenehmes Erlebnis wird.`,
            photo: Zoltan,
          },
          {
            title: "Angenehmes Lernen",
            name: "Gábor",
            comment: `Ich benutze Lexicos jetzt seit mehreren Monaten und kann mit Sicherheit sagen, dass es mein Leben verändert hat. Ich wurde an meinem Arbeitsplatz befördert! Ich war schon immer daran interessiert, eine neue Sprache zu beherrschen, hatte aber nie die Zeit oder die Ressourcen. Mit Lexicos kann ich in meinem eigenen Tempo und nach meinem eigenen Zeitplan lernen.`,
            photo: Gabor,
          },
          {
            title: "Verwende es seit ein paar Monaten.",
            name: "Mária",
            comment: `Ich benutze Lexicos jetzt seit mehreren Monaten und muss sagen, es hat eine große Veränderung für mich gebracht. Ich war schon immer daran interessiert, eine neue Sprache zu beherrschen, hatte aber nie die Gelegenheit, aber Lexicos hat das Lernen für mich so einfach und komfortabel gemacht. Heute verstehe und kommuniziere ich mit Menschen aus verschiedenen Kulturen, was meinen Horizont erweitert und mir neue Möglichkeiten eröffnet hat.`,
            photo: Maria,
          },
        ],
      },
    },
    course: {
      title: "Wie sieht ein Kurs aus?",
      right:
        "Auf der Lexicos-Plattform findest du keine langweiligen, trockenen Grammatikregeln, die du an einem Tag lernen musst. Du lernst Sätze und Ausdrücke, die im Alltag verwendet werden, und festigst sie dann mit einfachen Übungen und Wiederholungen im Gedächtnis. Das Ziel des Kurses ist es, das Lernen spaßig zu gestalten.",
      right2Title: "Echte Lektionen",
      right2Bottom:
        "Das Lernen auf jedem Niveau besteht aus Dutzenden von Lektionen. Wenn du dein Niveau nicht kennst - beginne mit den Grundlagen. Lerne Begrüßungen, Wochentage, deine Gefühle. Lerne, wie man Essen in einem Restaurant bestellt und wie man ein Taxi ruft. Jede Lektion enthält Beispiele aus dem Alltag. Jede Lektion ist ein wenig anders und besteht aus verschiedenen Aufgaben und Übungen, aber jede bildet ein Ganzes – sodass du die Sprache leicht beherrschen kannst. Du kannst die Lektion jederzeit starten.",
      flexDown: [
        {
          title: "Während des Lernens wirst du auf Elemente stoßen wie:",
          text: `
      Audioaufnahmen, die die richtige Aussprache lehren,
    `,
          text1: "Ausdrücke und Sätze aus dem wirklichen Leben,",
          text2: "Bilder, die dir helfen, visuell zu assoziieren",
          text3: "und sich Wörter und Ausdrücke zu merken,",
          text4: "...und viele andere.",
        },
        {
          title: "Aufgaben",
          text: `Unabhängig davon, ob du täglich Rätsel lösen möchtest oder solche Aktivitäten lieber vermeidest, ist es unbestreitbar, dass diese Rätsel hervorragend zur Entwicklung des Gedächtnisses geeignet sind und das Wiederholen des gelernten Materials ermöglichen.`,
        },
        {
          title: "Wiederholungen",
          text: `Fortgeschrittene Lernende können Vokabeln im kontinuierlichen Lernmodus wiederholen und ihr Wissen in jedem Fachbereich testen. Es enthält den gesamten verfügbaren Wortschatz aus jeder Kategorie. Ein Lernalgorithmus überprüft ständig den Wissensstand und passt die folgenden Lektionen entsprechend an.`,
        },
      ],
    },
    learningWithlexicos: {
      title: "Lernen auf der Lexicos Sprachplattform:",
      cards: [
        {
          icon: learning1,
          headline: `Hohe Qualität`,
          text: `
      Während der Entwicklung von Lexicos setzte sich ein Expertenteam eines der wichtigsten Ziele: das Erlernen einer Fremdsprache mit unserer Methode so schnell wie möglich zu gestalten (unabhängig vom Alter des Lernenden!).
        `,
        },
        {
          icon: learning2,
          headline: `Lektionen ohne das Haus zu verlassen`,
          text: `Um die Lexicos-Lernapp zu nutzen, brauchst du nur dein Telefon, deinen Computer oder dein Tablet. Mit Eureka kannst du überall lernen: in der Warteschlange, im Bus und vor allem zu Hause, ohne irgendwohin gehen zu müssen. Lernen beim Baden oder Gassi gehen? Mit Eureka ist das möglich!
      `,
          text2:
            "Das Lernen mit Lexicos spart viel Pendelzeit und du kannst das Lernen leicht in deinen Zeitplan integrieren!",
        },
        {
          icon: learning3,
          headline: `Kurze, aber systematische Lektionen`,
          text: `Das Wichtigste beim Sprachenlernen ist die Regelmäßigkeit. Gleichzeitig ist es für uns alle schwer, die notwendige Zeit zu finden. Aus diesem Grund wurde Eureka entwickelt, um in kurzen, aber systematischen Lektionen zu lehren.`,
        },
      ],
    },
    whylexicos: {
      title: "Wie sieht ein Kurs aus?",
      textUp:
        "Dies ist eine vielseitige Lernplattform, die dir hilft, eine neue Sprache so natürlich wie deine Muttersprache zu beherrschen.",
      textBottom:
        "Das Erlernen einer Fremdsprache kann dein Leben verändern. Es öffnet nicht nur ein Fenster zu einer Welt voller beruflicher und persönlicher Möglichkeiten, sondern entwickelt auch deinen Geist und stärkt dein Selbstvertrauen. Da Technologien wie das Internet Distanzen verringern und wir Mitglieder einer globalen Gemeinschaft werden, wird die Fähigkeit, Sprachen zu lernen, zu einem wesentlichen Bestandteil unserer Interaktion mit der Welt und unserem Erfolg.",
      cards: [
        {
          headline: `Lerne Ausdrücke und Sätze aus dem Alltag mit unserer fortschrittlichen Lerntechnologie.`,
          text: `Die Lektionen werden kontinuierlich an dein Niveau angepasst, sodass du nie Langeweile oder Motivationsverlust erlebst.`,
        },
        {
          headline: `Tauche in eine Fremdsprache ein. Versuche, eine neue Sprache vollständig basierend auf deinem kontextuellen Wissen zu verstehen.`,
          text: `Mit Tausenden von Grafiken und Audioaufnahmen wirst du wie ein Muttersprachler sehen und hören.`,
        },
        {
          headline: `Gewinne Selbstvertrauen im Sprechen. Das eigentliche Ziel des Sprachenlernens ist auch das Verstehen.`,
          text: `Mit Lexicos musst du nur den Wortschatz wiederholen, den du hörst - hab keine Angst zu sprechen! Unser System wird dich korrigieren.`,
        },
        {
          headline: `Lass keine Wörter aus deinem Gedächtnis rutschen. Lerne durch verschiedene Übungen.`,
          text: `Wir haben die Elemente der App sorgfältig so gestaltet, dass dein Geist nie Langeweile empfindet. Wir bereiten ständig neue Herausforderungen vor und erweitern Lexicos, um das Lernen unterhaltsam zu gestalten.`,
        },
      ],
    },
  },
  aboutUs: {
    hero: {
      title: "Mission",
      description: `Wir glauben, dass Sprache eine Brücke zwischen Kulturen bildet, und unsere Mission ist es, Kommunikationsbarrieren zu überwinden und Menschen durch Sprachunterricht zusammenzubringen.`,
    },
    why: {
      title: "Warum wir?",
      config: [
        {
          title: "Innovative Lehrmethoden",
          image: dlaczego1,
          description:
            "Unser ausgeklügelter und praxiserprobter Lernalgorithmus passt die Materialien an dein aktuelles Wissensniveau an und erweitert es durch effektives Lehren.",
        },
        {
          title: "Breite Palette an Sprachen",
          image: dlaczego2,
          description:
            "Wir bieten Kurse in den weltweit beliebtesten Sprachen an. Dies ermöglicht es dir, die Sprache auszuwählen, die deinen Bedürfnissen entspricht.",
        },
        {
          title: "Bildung auf unterhaltsame Weise",
          image: dlaczego3,
          description:
            "Unsere Plattform macht das Sprachenlernen zu einem unterhaltsamen und angenehmen Erlebnis, sodass du motiviert bist, Sprachen zu lernen.",
        },
      ],
    },
    mission: {
      config: [
        {
          title: "Personalisierung und Zugänglichkeit",
          description:
            "Unser effizienter maschineller Lernalgorithmus arbeitet ständig daran, angemessene Materialien für jede Person bereitzustellen. Lexicos ermöglicht es Lernenden, Muster selbst zu entdecken, ohne sich auf Sprachregeln konzentrieren zu müssen – genau wie du deine Muttersprache in deiner Kindheit gelernt hast.",
          image: zdjecie1,
        },
        {
          title: "Kombination aus Technologie und Wissenschaft",
          description:
            "Lexicos verwendet eine mutige Kombination aus Technologie und Wissenschaft, um den Geist in Aktion zu versetzen. Unzählige Listen von Wörtern und Ausdrücken zu wiederholen, ist langweilig, und dein Gehirn weiß das. Unsere Methode ist aufregend und effektiv und zwingt dich, erworbenes Wissen auf unterschiedliche Weise in bestimmten Momenten zu verwenden – dein Gehirn wird diese Lösung schätzen!",
          image: zdjecie2,
        },
        {
          title: "Gemeinschaft und Werte",
          description:
            "Sprachen helfen Menschen, sich zu verpflichten. Sprachenlernen erleichtert das Verständnis. Die Lexicos-Community bringt Menschen aus der ganzen Welt zusammen. Wir sind ehrgeizig und überschreiten ständig Grenzen. Wir glauben, dass Sprachenlernen Spaß machen kann und sind neugierig, neue Kulturen durch Sprache zu erkunden.",
          image: zdjecie3,
        },
      ],
    },
  },
  formSender: {
    formControll: {
      pageLink: "https://nyelvfejlesztesi-kozpont.eu/",
      title: "Interessiert an einem Kurs?",
      title1: "Möchtest du mehr Informationen?",
      subtitle: "Hinterlasse deine Telefonnummer - wir rufen dich zurück.",
      subtitle1:
        "Wenn du bereits ein Konto hast, fülle bitte die folgenden Felder aus:",
      button1: "Name und Nachname",
      button2: "Telefonnummer",
      button3: "Email",
      buttonSub: "Senden",
      checkOne:
        "Durch das Absenden dieses Formulars akzeptiere ich die Bedingungen und erkläre, dass ich die folgenden",
      checkOneTwo: "die",
      checkTwo: "Ich stimme der Kontaktaufnahme zu Marketingzwecken zu",
      textOne: "Bitte beachten Sie, dass",
      textTwo:
        "Der Verantwortliche für Ihre persönlichen Daten ist EOU Sp. z o.o., mit Sitz in Warschau, Młynarska 42 / 115, 01-171, eingetragen im Unternehmerregister, geführt vom Amtsgericht Warschau unter KRS5273057993, REGON: 525396900, NIP5273057993. Ihre persönlichen Daten werden im Zusammenhang mit der Anfrage über das Kontaktformular oder der Ausführung des abgeschlossenen Vertrags verarbeitet. Indem Sie uns kontaktieren, stimmen Sie freiwillig der Verarbeitung Ihrer personenbezogenen Daten zu den genannten Zwecken zu. Sie haben das Recht, auf Ihre Daten zuzugreifen, sie zu korrigieren, zu löschen oder die Verarbeitung einzuschränken, der Verarbeitung zu widersprechen und die Datenübertragbarkeit zu verlangen. Sie haben auch das Recht, Ihre Einwilligung jederzeit zu widerrufen, aber der Widerruf der Einwilligung berührt nicht die Rechtmäßigkeit der Verarbeitung, die auf der Einwilligung vor deren Widerruf beruht. Sie haben auch das Recht, eine Beschwerde bei der Aufsichtsbehörde einzureichen. Die Angabe von Daten ist freiwillig, kann jedoch erforderlich sein, um auf eine Anfrage zu antworten oder einen Vertrag auszuführen. Personenbezogene Daten werden für den Zeitraum gespeichert, der für die oben genannten Zwecke erforderlich ist, jedoch nicht länger als die Verjährungsfrist gemäß allgemeinem Recht. Bitte lesen Sie die Datenschutzbestimmungen sorgfältig durch",
      buttonMore: "Mehr lesen",
      buttonLess: "Weniger lesen",
      subOne: "Bedingungen",
      subTwo: "und Datenschutzbestimmungen.",
      buttonTXT: "Danke",
      rulesLink: "/Regulations_EN.pdf",
      privacyLink: "/Privacy_EN.pdf",
      product: "ed",
      market: "de",
      send_to_url: "https://crm.europa-edu.eu/api/v1/cms/create_order",
      utm_source: "Platform_Lexicos",
      product_variant: "de-en",
      server_link: "https://cms.europa-assets.eu/integration/lead-from-landing",
      currency: "eur",
      price: 57,
    },
  },
  footer: {
    privacy: "Datenschutz",
    privacyLink: "/Privacy_EN.pdf",
    rules: "Nutzungsbedingungen",
    rulesLink: "/Regulations_EN.pdf",
  },
};
